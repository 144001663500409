import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';


import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
    Container,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

import Slideshow from '@bahaide/shared/components/Slideshow';

const AndachtUndGebet = ({ data }) => (
    <InnerPage site="hda" title="Andacht und Gebet" description="">
        <IntroHolder
            title="Andacht und Gebet"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Haus der Andacht',
                'current-item': 'Andacht und Gebet',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#83222b',
                    fontSize: 16,
                    padding: '15px',
                    color: 'white',
                }}
                className="blockquote-text"
            >
                <p style={{ marginBottom: 0 }}>
                    Sie sind herzlich zu der wöchentlichen{' '}
                    <b>Sonntags-Andacht, 15 Uhr</b> eingeladen.
                </p>
            </div>
        </IntroHolder>
        <TwoColumns>
            <div id="content">
                <p>
                    Die Bahá’í-Häuser der Andacht auf der ganzen Welt sind dem
                    Ziel gewidmet, die Hinwendung zu Gott mit dem Dienst an der
                    Menschheit zu verbinden. Sie sind Orte des Gebets, der
                    Meditation, Besinnung und sind zur Lobpreisung Gottes
                    konzipiert, offen für alle, unabhängig von Religion oder
                    Glaubensrichtung, Nationalität oder Hintergrund.
                </p>
                <p>
                    Der Geist der Gemeinschaft und der Harmonie, der uns
                    verbindet, wird durch Andachten gestärkt. Für die Bahá’í ist
                    das tägliche Gebet wesentlich für geistiges Wohl und
                    Wachstum, ebenso wie leibliche Nahrung für den Erhalt des
                    Körpers wesentlich ist. Durch das Gebet lobt man Gott,
                    drückt seine Liebe zu Ihm aus oder bittet Ihn um Erkenntnis
                    oder Hilfe.
                </p>
                <p>
                    Die Schriften des Báb und Bahá’u’lláhs, ebenso wie die der
                    anderen Gottesoffenbarer beinhalten viele berührende,
                    ausdrucksvolle Gebete, die geistige Kraft spenden und sowohl
                    privat als auch bei Andachtsversammlungen z.B. im Haus der
                    Andacht gesprochen werden.
                </p>
            </div>

            <aside id="sidebar" className="wide-sidebar">
                <div className="widget">
                    <div className="video-wrapper">
                        <iframe
                            title="Andacht zum Schulbeginn 2020 im Bahá'í-Haus der Andacht von Europa"
                            src="https://www.youtube.com/embed/0MQLEoFk7N0"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                            allowfullscreen={true}
                        />
                    </div>
                    <br />
                </div>
            </aside>
        </TwoColumns>

        <div className="pages-block">
            <Container>
                <h2 className="h4 serif" style={{ color: 'white' }}>
                    Präsentation
                </h2>
                <h3 className="h2 serif" style={{ color: 'white' }}>
                    Gesprochene und vertonte Gebete
                </h3>
                <p className="serif" style={{ color: 'white' }}>
                    Hier ist eine Auswahl von gesprochenen und vertonten
                    Gebeten, aufgenommen im Haus der Andacht in Langenhain.
                </p>
                <Slideshow
                    slides={[
                        {
                            title: 'Shirin',
                            description:
                                'Gesprochenes Gebet zum Thema „Einheit“ aus den Bahá’i-Schriften in deutscher Sprache',
                            image: data.slideshowImage1,
                            soundcloudId: '647905947%3Fsecret_token%3Ds-dm5p7',
                        },
                        {
                            title: 'Marcel',
                            description:
                                'Vertontes Gebet aus den Bahá’i-Schriften in deutscher Sprache',
                            image: data.slideshowImage2,
                            soundcloudId: '647905968%3Fsecret_token%3Ds-U1d75',
                        },
                        {
                            title: 'Nura und Freundin',
                            description:
                                'Vertontes Zitat aus den Bahá’i-Schriften. Vorgetragen von zwei Jugendlichen',
                            image: data.slideshowImage3,
                            soundcloudId: '647905959%3Fsecret_token%3Ds-NOz9Y',
                        },
                        {
                            title: 'Pascal',
                            description:
                                'Gesprochenes Gebet zum Thema „Tugenden“ aus den Bahá’i Schriften',
                            image: data.slideshowImage4,
                            soundcloudId: '647905953%3Fsecret_token%3Ds-gMJmy',
                        },
                        {
                            title: 'Maryam',
                            description:
                                'Vertontes Zitat aus den Bahá’i Schriften zum Thema „Loslösung“ in englischer Sprache',
                            image: data.slideshowImage5,
                            soundcloudId: '647905962%3Fsecret_token%3Ds-prBYo',
                        },
                    ]}
                />
            </Container>
        </div>

        <TwoColumns>
            <div id="content">
                <p>
                    Das Zusammenspiel von Andacht und gemeinsamem Handeln kommt
                    nicht nur am Haus der Andacht zum Ausdruck. An vielen
                    unterschiedlichen Orten in Deutschland, Europa und der Welt
                    sind Menschen aller Altersgruppen, Religionen und jeglicher
                    Herkunft in einen Prozess der Gemeindebildung involviert.
                    Man arbeitet gemeinsam für sowohl den persönlichen als auch
                    den gemeinschaftlichen Fortschritt in einem Prozess des
                    Gebets, des Studiums, der Beratung, des Handelns und der
                    Reflexion. Damit dient die Andacht, das Gebet und die
                    Meditation sowohl der Entwicklung des Individuums als auch
                    der Fortentwicklung der Gesellschaft – diese gehen Hand in
                    Hand.
                </p>

                <h2>Gebete und Zitate</h2>

                <NormalQuote reference="Bahá’u’lláh">
                    Selig der Ort und das Haus und der Platz und die Stadt und
                    das Herz und der Berg und das Obdach und die Höhle und das
                    Tal und das Land und das Meer und die Insel und die Au, wo
                    Gottes gedacht und Sein Lob gepriesen wird.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá">
                    Es gibt nichts Lieblicheres in der Welt des Seins als das
                    Gebet! … Gebet ist Zwiesprache mit Gott. … Sie schafft
                    Geistigkeit, Bewusstheit und himmlische Gefühle, erzeugt
                    neue Anziehung zum Königreich und erweckt die
                    Empfänglichkeit der geistigen Natur.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá">
                    Er ist Gott! O Herr, wie sollen wir Dir danken? Grenzenlos
                    ist Deine Großmut, und unsere Dankbarkeit ist nur begrenzt.
                    Wie kann das Begrenzte dem Grenzenlosen Dank erweisen?
                    Unfähig sind wir, Dir Dank zu sagen für Deine Gnadengaben.
                    Völlig machtlos wenden wir uns Deinem Reiche zu und bitten
                    Dich, Deine Gaben und Segnungen zu mehren. Du bist der
                    Geber, der Schenkende, der Gewaltige.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá">
                    O mein Gott! O mein Gott! Ruhm sei Dir, dass Du mich
                    stärkest, Deine Einheit zu bekennen, dass Du mich hinziehst
                    zum Wort Deiner Einzigkeit, mich entflammst durch das Feuer
                    Deiner Liebe, so dass ich mich Deinem Gedenken hingebe und
                    dem Dienst an Deinen Freunden und Dienerinnen.
                    <br />O Herr, hilf mir, sanft und bescheiden zu sein, und
                    gib mir die Kraft, dass ich mich von allem löse und mich an
                    den Saum des Gewandes Deiner Herrlichkeit halte, auf dass
                    mein Herz erfüllt werde von Deiner Liebe und kein Platz mehr
                    darin sei für die Liebe zur Welt und die Bindung an ihre
                    Wesenszüge. <br />
                    O Gott! Heilige mich von allem außer Dir, reinige mich von
                    den Schlacken der Sünden und Übertretungen, schenke mir ein
                    geistiges Herz und geistigen Sinn.
                    <br />
                    Wahrlich, Du bist barmherzig, und wahrlich, Du bist der
                    Freigebige, dessen Hilfe alle Menschen begehren.
                </NormalQuote>

                <NormalQuote reference="Báb">
                    O Herr! Zu Dir nehme ich Zuflucht und auf Deine Zeichen
                    richte ich mein Herz. O Herr! Ob auf Reisen oder zu Hause,
                    in meinem Beruf oder bei meiner Arbeit, setze ich all mein
                    Vertrauen in Dich. So gewähre mir Deine allgenügende Hilfe
                    und mache mich von allem unabhängig, o Du, der Du
                    unübertroffen bist in Deinem Erbarmen.
                    <br />
                    Lasse mir meinen Anteil zukommen, o Herr, wie es Dir
                    gefällt, und mache mich zufrieden mit dem, was Du für mich
                    verordnest.
                    <br />
                    Dein ist die unumschränkte Befehlsgewalt.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh">
                    O mein Gott, Du Gott der Gnadenfülle und des Erbarmens! Du
                    bist der König, durch dessen Befehl die ganze Schöpfung ins
                    Dasein gerufen ist. Du bist der Allgroßmütige, den die Taten
                    Seiner Diener niemals davon abhalten, Seine Gnade kundzutun
                    und Seine Freigebigkeit zu offenbaren. Ich flehe Dich an:
                    Lass diesen Diener erreichen, was ihm in allen Deinen Welten
                    Erlösung bringt.
                    <br />
                    Du bist wahrlich der Allmächtige, der Gewaltigste, der
                    Allwissende, der Allweise.
                </NormalQuote>

                <NormalQuote reference="Báb">
                    Gibt es einen Befreier von Schwierigkeiten außer Gott?
                    Sprich: Gelobt sei Gott! Er ist Gott! Alle sind Seine Diener
                    und alle unterstehen Seinem Befehl.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá">
                    O Du gütiger Herr! Du hast die ganze Menschheit aus dem
                    gleichen Stamm erschaffen. Du hast bestimmt, dass alle der
                    gleichen Familie angehören. In Deiner heiligen Gegenwart
                    sind alle Deine Diener, die ganze Menschheit findet Schutz
                    in Deinem Heiligtum. Alle sind um Deinen Gabentisch
                    versammelt; alle sind erleuchtet vom Lichte Deiner
                    Vorsehung.
                    <br />
                    O Gott! Du bist gütig zu allen, Du sorgst für alle, Du
                    beschützest alle, Du verleihst allen Leben. Du hast einen
                    jeden mit Gaben und Fähigkeiten ausgestattet, und alle sind
                    in das Meer Deines Erbarmens getaucht.
                    <br />O Du gütiger Herr! Vereinige alle. Gib, dass die
                    Religionen in Einklang kommen und vereinige die Völker, auf
                    dass sie einander ansehen wie <em>eine</em> Familie und die
                    ganze Erde wie <em>ein</em> Heim. O dass sie doch in
                    vollkommener Harmonie zusammenlebten!
                    <br />
                    O Gott! Erhebe das Banner der Einheit der Menschheit. O
                    Gott! Errichte den Größten Frieden. Schmiede Du, o Gott, die
                    Herzen zusammen. O Du gütiger Vater, Gott! Erfreue unsere
                    Herzen durch den Duft Deiner Liebe. Erhelle unsere Augen
                    durch das Licht Deiner Führung. Erquicke unsere Ohren mit
                    dem Wohlklang Deines Wortes und beschütze uns alle in der
                    Feste Deiner Vorsehung.
                    <br />
                    Du bist der Mächtige und der Kraftvolle, Du bist der
                    Vergebende und Du bist der, welcher die Mängel der ganzen
                    Menschheit übersieht.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh">
                    In Deiner Obhut bin ich erwacht, o mein Gott, und wer Deine
                    Obhut sucht, dem steht es an, in Deinem schützenden
                    Heiligtum und in Deiner festen Burg zu bleiben. Erhelle, o
                    mein Herr, mit dem strahlenden Morgenglanz Deiner
                    Offenbarung mein inneres Sein, so wie Du mein äußeres Sein
                    mit dem Frühlicht Deiner Gunst erleuchtet hast.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá">
                    O Herr, ich habe mein Angesicht Deinem Reich der Einheit
                    zugewandt und lasse mich versinken im Meere Deiner Gnade.
                    <br />
                    O Herr, erleuchte mein Auge mit Deinem Licht in dieser
                    dunklen Nacht und beglücke mich mit dem Wein Deiner Liebe in
                    dieser wunderbaren Zeit.
                    <br />
                    O Herr, lasse mich Deinen Ruf hören und öffne vor meinem
                    Angesicht die Tore Deines Himmels, damit ich das Licht
                    Deiner Herrlichkeit schaue und hingezogen werde zu Deiner
                    Schönheit.
                    <br />
                    Wahrlich, Du bist der Geber, der Großmütige, der
                    Barmherzige, der Verzeihende.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh">
                    Dein Name ist meine Heilung, o mein Gott, Dein Gedenken
                    meine Arznei, Deine Nähe meine Hoffnung und die Liebe zu Dir
                    mein Gefährte. Dein Erbarmen ist meine Heilung und Hilfe in
                    beiden Welten, in dieser und der künftigen. Du bist wahrlich
                    der Allgütige, der Allwissende, der Allweise.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh">
                    O mein Gott, o mein Gott! Einige die Herzen Deiner Diener
                    und enthülle ihnen Deinen großen Plan. Gib, dass sie Deinen
                    Geboten folgen und Deinem Gesetz die Treue halten. Hilf
                    ihnen, o Gott, in ihrem Bemühen und verleihe ihnen die
                    Kraft, Dir zu dienen.
                    <br />O Gott! Überlasse sie nicht sich selbst, sondern lenke
                    ihre Schritte durch das Licht Deiner Erkenntnis und beglücke
                    ihre Herzen durch Deine Liebe. Wahrlich, Du bist ihr Helfer
                    und ihr Herr.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá">
                    O Du gütiger Herr! Diese lieblichen Kinder sind das Werk
                    Deiner machtvollen Hand, die wundersamen Zeichen Deiner
                    Größe. O Gott! Behüte diese Kinder, hilf ihnen gnädiglich,
                    dass sie erzogen und fähig werden, der Menschheit zu dienen.
                    O Gott! Diese Kinder sind Perlen, lass sie heranwachsen in
                    der Muschel Deiner Güte. Du bist der Großmütige, der
                    Alliebende.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá">
                    O Herr! Lass diesen jungen Menschen strahlen, erweise diesem
                    armen Geschöpf Deine Freigebigkeit. Schenke ihm Wissen,
                    gewähre ihm jeden Morgen neue Kraft und behüte ihn unter dem
                    Obdach Deines Schutzes, dass er frei von Irrtum werde, sich
                    dem Dienste Deiner Sache hingebe, die Widerspenstigen leite,
                    die Unglücklichen führe, die Gefangenen befreie und die
                    Achtlosen erwecke, so dass alle durch Dein Gedenken und
                    Deinen Lobpreis gesegnet sind.
                    <br />
                    Du bist der Mächtige, der Kraftvolle.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh">
                    Gib, o mein Herr, dass die zu Dir Emporgestiegenen Zuflucht
                    finden bei Ihm, dem erhabensten Gefährten, und im Schatten
                    des Tabernakels Deiner Erhabenheit und des Heiligtums Deiner
                    Herrlichkeit wohnen. Benetze sie, o mein Herr, aus dem Meere
                    Deiner Vergebung mit dem, was sie würdig macht, in Deinem
                    erhabensten Reich und Deinem allhöchsten Hoheitsgebiet zu
                    weilen, solange Deine unumschränkte Herrschaft währt.
                    <br />
                    Mächtig bist Du zu tun, was Dir gefällt.
                </NormalQuote>
            </div>
        </TwoColumns>
    </InnerPage>
);

export default AndachtUndGebet;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "HDA-Andacht-Winter.jpg" }) {
            ...fluidImage
        }
        slideshowImage1: file(
            relativePath: { eq: "hda-andacht-gebet-slide-1.jpg" }
        ) {
            ...fluidImage
        }
        slideshowImage2: file(
            relativePath: { eq: "hda-andacht-gebet-slide-2.jpg" }
        ) {
            ...fluidImage
        }
        slideshowImage3: file(
            relativePath: { eq: "hda-andacht-gebet-slide-3.JPG" }
        ) {
            ...fluidImage
        }
        slideshowImage4: file(
            relativePath: { eq: "hda-andacht-gebet-slide-4.JPG" }
        ) {
            ...fluidImage
        }
        slideshowImage5: file(
            relativePath: { eq: "hda-andacht-gebet-slide-5.JPG" }
        ) {
            ...fluidImage
        }
        flyerImage: file(
            relativePath: { eq: "HDA_Veranstaltungen.png" }
        ) {
            ...fluidImage
        }
    }
`;
