import React, { Component } from 'react';

import SoundcloudPlayer from './SoundcloudPlayer';
import Img from 'gatsby-image';
import Icon from './Icon';

class Slideshow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: this.props.slides,
            currentIndex: 0,
        };
    }

    _prev = () => {
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex - 1,
        }));
    };

    _next = () => {
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex + 1,
        }));
    };

    render() {
        const { slides, currentIndex } = this.state;

        return (
            <>
                <h4 className="slide-hide-content serif" style={{ marginBottom: 10 }}>Galerie</h4>
                {slides.map((slide, i) =>
                    i === currentIndex ? (
                        <div className="slide" key={i}>
                            <div className="img-holder slide-image">
                                <Img
                                    fluid={
                                        slide.image.childImageSharp.fluid
                                    }
                                />
                            </div>
                            <div className="slide-additional-media">
                                {slide.soundcloudId && 
                                    <SoundcloudPlayer id={slide.soundcloudId} />
                                }
                                {slide.description &&
                                    <p>                                        
                                        {slide.description}
                                    </p>
                                }
                            </div>
                        </div>
                    ) : (
                        ''
                    )
                )}

                <div className="slide-controls">
                    <button
                        disabled={currentIndex > 0 ? false : true}
                        onClick={() => this._prev()}
                    >
                        <Icon icon="arrow-left" />
                    </button>
                    <button
                        disabled={
                            currentIndex < slides.length - 1 ? false : true
                        }
                        onClick={() => this._next()}
                    >
                        <Icon icon="arrow-right" />
                    </button>
                </div>
            </>
        );
    }
}

export default Slideshow;
