import React from 'react';

const SoundcloudPlayer = ({ id }) => (
    <iframe
        width="100%"
        height="166"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&color=%23193b5e&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&show_playcount=false&show_artwork=false`}
    />
);

export default SoundcloudPlayer;
